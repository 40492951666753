import React, { useState } from "react";
import Layout from "../../components/layout/Layout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import axios from "axios";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoginPrompt from "../../components/app/loginPrompt"
import Input from "../../components/forms/Input"
import Select from "../../components/forms/Select"
import TextArea from "../../components/forms/TextArea"
import Hidden from "../../components/forms/Hidden"


 const formSchema = Yup.object().shape({
  Name: Yup.string().required("Required"), 
});


const Testa= () => {
  
  
 // Axios server state handling
  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
	setServerState({ok, msg});
  };
  const handleOnSubmit = (values, actions) => {
	  
	axios({
	  method: "POST",
	  url: "https://formspree.io/f/mdovkypv",
	  data: values,
	})
	  .then(response => {
		actions.setSubmitting(false);
		actions.resetForm();
		handleServerResponse(true, "Thanks");
 	  })
	  .catch(error => {
		actions.setSubmitting(false);
		handleServerResponse(false, error.response.data.error);
	  });
	  return ("Yippee");
  };
  
  
  

  
  return (
	<Layout>
	<Container>
	
	
	{isLoggedIn() ? (
	  <div>
		<Type type="h2">Add a new project</Type>
		<Formik
		  initialValues={{
			Name: "New project name",
		  }}
		  onSubmit={handleOnSubmit}
		  validationSchema={formSchema}
		>
		  {({ values,
			 errors,
			 touched,
			 handleChange,
			 handleBlur,
			 handleSubmit,
			 isSubmitting 
		   }) => (
			
			<Form id="newproject" noValidate >
			  
			  
			  
			  <Field id="BlueprintsL1" name="BlueprintsL1" fieldLabel="BlueprintsL1" component={Hidden} />
																												   
				   
				   
			  <div className="flex flex-col space-y-12 w-2/3">

				<Field id="Name" name="Name" fieldLabel="Name" component={Input} />






			  </div>
			  
			  <button type="submit" className="mt-16 bg-green rounded-lg py-4 px-8 text-white border text-lg border-green hover:bg-white hover:text-green font-bold" disabled={isSubmitting}>
				Submit
			  </button>
			  
			  <div>
			 {serverState && (
			   <div className="mt-8">
			   <div className={!serverState.ok ? "errorMsg" : ""}>
				 {serverState.msg}
			   </div>
			   </div>
			 )}
			  </div>
			</Form>
		  )}
		</Formik>
	  </div> 
   ) : (
	   <div>
		 <LoginPrompt/>
	   </div>     
	 )}



   </Container>
	</Layout>
  )
}

export default Testa

export const Head = () => <title>Framework</title>

